import type { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';

import base from '@/core/lib/translation/base';
import NotFound from '@/core/pages/NotFound';
import FormLayout from '@/next/layouts/FormLayout';

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      ...(await serverSideTranslations('fr', [...base, 'not-found'])),
      path: '/',
    },
  };
};

NotFound.getLayouts = page => {
  return <FormLayout>{page}</FormLayout>;
};

export default NotFound;
