import React from 'react';

const FormLayout: React.FC<React.PropsWithChildren> = ({ children }) => (
  <main id="app" className="flex min-h-screen w-full flex-col items-center bg-bg-primary px-4 pt-4 md:bg-bg-pale md:px-6 md:pt-7 lg:px-6 xl:px-24">
    <div className="grid size-full grid-cols-2 gap-x-4 md:grid-cols-8 md:gap-x-5 lg:grid-cols-12 lg:gap-x-6">
      <div className="col-span-2 place-content-start bg-bg-primary md:col-span-6 md:col-start-2 md:p-5 lg:col-span-6 lg:col-start-4">{children}</div>
    </div>
  </main>
);

export default FormLayout;
