import React from 'react';

import Link from '@/core/components/Link';
import { useComponentsContext } from '@/core/lib/components/components.context';
import Routes from '@/core/lib/router/routes';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import type { PageWithLayoutsAndProviders } from '@/core/pages/page.types';

const NotFound: PageWithLayoutsAndProviders = () => {
  const { Page, Content } = useComponentsContext();
  const { t } = useTranslationContext(['not-found']);

  return (
    <Page>
      <Content>
        <div className="mt-4 flex w-full justify-center space-x-2 px-4">
          <div className="flex h-full w-100 flex-col items-center justify-items-start">
            <h1 className="text-body-secondary mb-2 text-center font-semibold text-content-primary">{t('title', { ns: 'not-found' })}</h1>
            <div className="flex w-full flex-col space-y-20">
              <Link variant="primary" href={new Routes.HomeRoute()} size="medium">
                {t('link', { ns: 'not-found' })}
              </Link>
            </div>
          </div>
        </div>
      </Content>
    </Page>
  );
};

export default NotFound;
